import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpBtn from '../../buttons/SignUpBtn/SignUpBtn';
import '../Registration/registration.css';
// import './module.css'

const ForgotPassword = ({ t, textBtn }) => {
    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false);
    const [errorMessage, setErrorMessage] =useState('')

    useEffect(() => {
        const contentElement = document.querySelector('.content');
        if (contentElement) {
            contentElement.style.display = 'flex';
            contentElement.style.alignItems = 'center';
            contentElement.style.justifyContent = 'center';
        }
        return () => {
            if (contentElement) {
                contentElement.style.display = '';
                contentElement.style.alignItems = '';
                contentElement.style.justifyContent = '';
            }
        };
    }, []);

    const [formData, setFormData] = useState({
        email: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrorMessage('')
        // Убираем ошибку при фокусе на поле
        if (errors[e.target.name]) {
            setErrors({
                ...errors,
                [e.target.name]: ''
            });
        }
    };

    const validate = () => {
        let errors = {};
        if (!formData.email) {
            errors.email = t('error2');
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = t('error3');
        }
        return errors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        setTimeout(() => {
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 600); // Убираем тень через 600 мс
        }, 150);
        if (Object.keys(validationErrors).length === 0) {
            console.log('Форма отправлена:', formData);
            // Пример отправки данных
            // fetch('/api/register', {
                fetch('/api/reset-password?email=' + formData.email)
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    if (data.message) {
                        navigate('/reset');
                    } else if (data.error === 'No user') {
                        setErrorMessage(t('error4'))
                    } else if (data.error === 'No approve') {
                        setErrorMessage(t('error5'))
                    }
                })
                .catch(error => console.error('Error:', error));
        }
    };

    return (
        <form className='formAuth' onSubmit={handleSubmit}>
            <div className="formName">
                <h2>{t('passwordRecovery')}</h2>
                <p>{t('enter_email_below')}</p>
            </div>
            <div className='formItem' style={{ position: 'relative' }}>
                <label>{t('email')} <span className='danger'>*</span></label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="example@mail.com"
                    maxLength={56}
                    style={{
                        borderColor: errors.email ? 'red' : '',
                    }}
                    autoFocus
                />
                {errors.email && <p className="error">{errors.email}</p>}
            </div>
            <SignUpBtn
                type="submit"
                isClicked={isClicked}
                onClick={handleSubmit}
                t={t}
                textBtn={textBtn}
                style={{
                    width: '100%',
                }}
            />
            {errorMessage ?
            <p className='errorMessage'>
                {errorMessage} 
            </p>
            : ''}
        </form>
    );
};

export default ForgotPassword;
// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';

// Создаем slice для watchlist
const watchlistSlice = createSlice({
    name: 'watchlist',
    initialState: [],
    reducers: {
        addVehicle: (state, action) => {
            state.push(action.payload); // Добавляем новую машину в массив
        },
        removeVehicle: (state, action) => {
            return state.filter(vehicle => vehicle.id !== action.payload); // Удаляем машину по id
        },
        clearWatchlist: (state) => {
            state.length = 0; // Очищаем массив, изменяя его длину
        },
    },
});

// Экспортируем экшены для использования в компонентах
export const { addVehicle, removeVehicle, clearWatchlist } = watchlistSlice.actions;

// Создаем store и подключаем наш slice
export const store = configureStore({
    reducer: {
        watchlist: watchlistSlice.reducer,
    },
});

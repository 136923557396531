import React, { useEffect } from 'react'
import './welcome.css'
import SignUpBtn from '../../buttons/SignUpBtn/SignUpBtn'
import VehiclesWave from '../../components/VehiclesWave/VehiclesWave';
import a from '../../assets/img/1.svg'
import b from '../../assets/img/2.svg'
import c from '../../assets/img/3.svg'
import aa from '../../assets/img/4.svg'
import bb from '../../assets/img/5.svg'
import cc from '../../assets/img/6.svg'
import aaa from '../../assets/img/7.svg'
import bbb from '../../assets/img/8.svg'
import ccc from '../../assets/img/9.svg'
import aab from '../../assets/img/10.svg'
import abb from '../../assets/img/11.svg'
import aac from '../../assets/img/12.svg'
import acc from '../../assets/img/13.svg'
import abc from '../../assets/img/14.svg'
import acb from '../../assets/img/15.svg'
import baa from '../../assets/img/16.svg'
import bba from '../../assets/img/17.svg'
import bbc from '../../assets/img/18.svg'
import bac from '../../assets/img/19.svg'
import caa from '../../assets/img/20.svg'
import cca from '../../assets/img/21.svg'
import cbb from '../../assets/img/22.svg'
import ccb from '../../assets/img/23.svg'
import cbc from '../../assets/img/24.svg'
import bab from '../../assets/img/25.svg'
import bcb from '../../assets/img/26.svg'
import cab from '../../assets/img/27.svg'

const imageUrls = [
  a, b, c, aa, bb, cc, aaa, bbb, ccc, aab, abb, aac, acc, abc, acb, baa, bba, bbc, bac, caa, cca, cbb, ccb, cbc, bab, bcb, cab
];

export default function Welcome({ t, textBtn, login }) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);
  const welcomeh1 = t('welcomeh1').replace(/\n/g, '<br />');
  const welcomep = t('welcomep').replace(/\n/g, '<br />');
  return (
    <div className='welcomeWrapper'>
      <h1 dangerouslySetInnerHTML={{ __html: welcomeh1 }}></h1>
      <p dangerouslySetInnerHTML={{ __html: welcomep }}></p>
        <SignUpBtn
          t={t}
          textBtn={textBtn}
          style={{width: '280px'}}
        />
      <VehiclesWave images={imageUrls} />
    </div>
  )
}

import React, { useState, useEffect, useCallback } from 'react';
import './auction.css';
import MainFilter from '../../components/MainFilter/MainFilter';
import Filters from '../../components/Filters/Filters';
import Match from '../../components/Match/Match';

export default function Auction({ t, width }) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [column, setColumn] = useState(true);
  const [filteredCars, setFilteredCars] = useState([]);
  const [openFilter, setOpenFilter] = useState(width <= 1200 ? false : true);
  const [filter, setFilter] = useState(
    {
      email: localStorage.getItem('email'),
      status: activeIndex === 1 ? 'active' : 'upcoming',
      make: null,
      models: null,
      year_st: null,
      year_en: null,
      odometer_st: null,
      odometer_en: null,
    }
  );
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  // useEffect(() => {
  //   fetchMakes();
  // }, [])

  // useEffect(() => {
  //   setLoading(true);
  //   if (filter.make !== null) {
  //     fetchModels();
  //   }
  //   fetchCars();

  // }, [activeIndex, filter])

  const nameFilter = [
    t('upcomming'),
    t('active'),
  ];

  // const fetchCars = async () => {
  //   const filterWithJsonModels = {
  //     ...filter,
  //     models: filter.models !== null ? JSON.stringify(filter.models) : null
  //   };

  //   const cleanedFilter = Object.fromEntries(
  //     Object.entries(filterWithJsonModels).filter(([_, value]) => value != null)
  //   );

  //   const queryString = new URLSearchParams(cleanedFilter).toString();
  //   const response = await fetch(`/api/vehicle-list?${queryString}`);
  //   // const response = await fetch('/api/vehicle-list?status=' + (activeIndex === 1 ? 'active' : 'upcoming'));
  //   if (response.ok) {
  //     const data = await response.json();
  //     setFilteredCars(data);
  //     setLoading(false);
  //   }
  // }

  // const fetchMakes = async () => {
  //   const response = await fetch('/api/makes-list');
  //   if (response.ok) {
  //     const data = await response.json();
  //     setMakes(data);
  //   }
  // }

  // const fetchModels = async () => {
  //   const response = await fetch('/api/models-list?make=' + filter.make);
  //   if (response.ok) {
  //     const data = await response.json();
  //     setModels(data);
  //   }
  // }
  const fetchCars = useCallback(async () => {
    const filterWithJsonModels = {
      ...filter,
      models: filter.models !== null ? JSON.stringify(filter.models) : null
    };
  
    const cleanedFilter = Object.fromEntries(
      Object.entries(filterWithJsonModels).filter(([_, value]) => value != null)
    );
  
    const queryString = new URLSearchParams(cleanedFilter).toString();
    const response = await fetch(`/api/vehicle-list?${queryString}`);
    
    if (response.ok) {
      const data = await response.json();
      setFilteredCars(data);
      setLoading(false);
    }
  }, [filter]);
  
  // Мемоизируем fetchMakes
  const fetchMakes = useCallback(async () => {
    const response = await fetch('/api/makes-list');
    if (response.ok) {
      const data = await response.json();
      setMakes(data);
    }
  }, []);
  
  // Мемоизируем fetchModels
  const fetchModels = useCallback(async () => {
    const response = await fetch('/api/models-list?make=' + filter.make);
    if (response.ok) {
      const data = await response.json();
      setModels(data);
    }
  }, [filter.make]);
  
  // Объединённый useEffect
  useEffect(() => {
    // Вызов fetchMakes только при монтировании
    fetchMakes();
  
    // При изменении фильтров и activeIndex
    setLoading(true);
    
    // Если make выбран, вызываем fetchModels
    if (filter.make !== null) {
      fetchModels();
    }
  
    // Всегда вызываем fetchCars
    fetchCars();
  
  }, [fetchMakes, fetchModels, fetchCars, activeIndex, filter]);


  return (
    <div className='auction'>
      { width >= 1024 &&
        <div className="topContainer">
          <h1>{t('browse')}</h1>
          <p>{t('find')}</p>
        </div>
      }
      <MainFilter 
        activeIndex={activeIndex} 
        setActiveIndex={setActiveIndex} 
        column={column} 
        setColumn={setColumn} 
        setFilter={setFilter} 
        t={t} 
        width={width} 
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
      />
      <div className="centerContainer">
        <Filters
          filter={filter}
          setFilter={setFilter}
          makes={makes}
          models={models}
          t={t}
          width={width}
          openFilter={openFilter}
        />
        <Match nameFilter={nameFilter[activeIndex]} column={column} cars={filteredCars} loading={loading} t={t} width={width} />
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import AddWatch from '../../buttons/AddWatch/AddWatch';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { addVehicle, clearWatchlist } from '../../store';
import 'react-toastify/dist/ReactToastify.css';
import './item.css'
import copy from '../../assets/img/copy.svg'
import carfaximg from '../../assets/img/carfax.svg'
import safety from '../../assets/img/safety.svg'
import mechanical from '../../assets/img/mechanical.svg'
import exterior from '../../assets/img/exterior.svg'
import interior from '../../assets/img/interior.svg'
import note from '../../assets/img/note.svg'
import tick from '../../assets/img/tick.svg'

export default function Item({ t, width }) {
    const [mainPhoto, setMainPhoto] = useState('');
    const [photos, setPhotos] = useState([])
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [maxThumbnails, setMaxThumbnails] = useState(0); // Начальное значение для миниатюр
    const [open, setOpen] = useState(false)
    const thumbnailsContainerRef = useRef(null);
    const thumbnailsOverContainerRef = useRef(null);
    const [car, setCar] = useState([])

    const [addWatch, setAddWatch] = useState(false)
    const dispatch = useDispatch();

    const watchlist = useSelector(state => state.watchlist);

    useEffect(() => {

        if (car && car.origin_id) { // Проверяем, что car и car.origin_id существуют
            if (Array.isArray(watchlist)) { // Проверяем, что watchlist является массивом
                const isInWatchlist = watchlist.some(item => {
                    return item.vehicle && item.vehicle.origin_id === car.origin_id; // Проверяем, что item.vehicle существует
                });
                setAddWatch(isInWatchlist);
            } else {
                console.error('Ошибка: watchlist не является массивом:', watchlist);
            }
        } else {
            console.error('Ошибка: отсутствует car или car.origin_id:', car);
        }

    }, [watchlist, car]);


    useEffect(() => {
        function getLastPartOfUrl(url) {
            const parts = url.split('/');
            return parts[parts.length - 1]; // Возвращаем последний элемент массива
        }
        const carId = getLastPartOfUrl(window.location.href)

        fetch(`/api/vehicle?vehicle_id=${carId}`)
            .then(response => response.json())
            .then(data => {
                // console.log('Success:', data);
                setCar(data)
                console.log(data)
                if (data.main_photo) {
                    setMainPhoto(data.main_photo)
                }
                const photoLinks = data.damages.map(damage => damage.photo_link)
                setPhotos([
                    ...(data.main_photo ? [data.main_photo] : []),
                    ...(data.photos.length > 0 ? data.photos : []),
                    ...(data.damages.length > 0 ? photoLinks : [])
                ]);
            })
            .catch(error => console.error('Error:', error));
    }, [])

    useEffect(() => {
        const calculateMaxThumbnails = () => {
            if (thumbnailsContainerRef.current) {
                const containerWidth = thumbnailsContainerRef.current.offsetWidth;
                const thumbnailWidth =(width <= 767 ? 50 :112) + 12; // ширина миниатюры + промежуток между ними
                const thumbnailsFit = Math.floor(containerWidth / thumbnailWidth); // Сколько миниатюр помещается
                setMaxThumbnails(thumbnailsFit);
            }
        };

        const resizeObserver = new ResizeObserver(calculateMaxThumbnails);

        if (thumbnailsContainerRef.current) {
            resizeObserver.observe(thumbnailsContainerRef.current);
        }

        // Вызываем для установки начального значения
        calculateMaxThumbnails();

        // Очищаем наблюдателя при размонтировании компонента
        return () => {
            resizeObserver.disconnect();
        };
    });

    useEffect(() => {
        // Добавляем или удаляем класс на body в зависимости от состояния
        if (open) {
            document.body.classList.add('bodyover');
        } else {
            document.body.classList.remove('bodyover');
        }

        // Очищаем класс при размонтировании компонента
        return () => {
            document.body.classList.remove('bodyover');
        };
    }, [open]);
    

    if (photos.length === 0) {
        return <div>{t('loading')}...</div>;
    }

    function formatLaunchDate(timestamp) {
        const date = new Date(timestamp);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // Преобразование в 12-часовой формат времени
        const hours = date.getHours() % 12 || 12; // Перевод часов в 12-часовой формат
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Форматирование минут
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // Определение AM/PM
        const timeFormatted = `${hours}:${minutes} ${ampm}`;

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0 в JavaScript
        const year = date.getFullYear();

        const dateFormatted = `${day}.${month}.${year}`;

        if (date.toDateString() === today.toDateString()) {
            return `${t('launch1')} ${timeFormatted}`;
        } else if (date.toDateString() === yesterday.toDateString()) {
            return `${t('launch2')} ${timeFormatted}`;
        } else {
            return `${t('launch3')} ${dateFormatted} ${t('at')} ${timeFormatted}`;
        }
    }

    const handleDamageClick = (photo, index) => {
        setOpen(true);
        setMainPhoto(photo);
        setSelectedPhotoIndex(index);
    }

    const interiorDamages = car.damages.filter(damage => damage.category.id === 1);
    const exteriorDamages = car.damages.filter(damage => damage.category.id === 2);
    const mechanicalDamages = car.damages.filter(damage => damage.category.id === 3);
    const safetyDamages = car.damages.filter(damage => damage.category.id === 4);

    const DamageList = ({ damages, categoryId }) => (
        damages.length > 0 ? (
            <ul>
                {damages.map((item, index) => {
                    // Глобальный индекс для фотографий
                    const globalIndex = photos.findIndex(photo => photo === item.photo_link);

                    return (
                        <li key={index} onClick={() => handleDamageClick(item.photo_link, globalIndex)}>
                            {item.name}
                        </li>
                    );
                })}
            </ul>
        ) : (
            <p className='noneRep'>{t('none_rep')}</p>
        )
    );

    const handleThumbnailClick = (photo, index) => {
        setMainPhoto(photo);
        setSelectedPhotoIndex(index);
    };

    const rightPaginationClick = () => {
        const nextIndex = (selectedPhotoIndex + 1) % photos.length;
        setMainPhoto(photos[nextIndex]);
        setSelectedPhotoIndex(nextIndex);
    };

    const leftPaginationClick = () => {
        const prevIndex = (selectedPhotoIndex - 1 + photos.length) % photos.length;
        setMainPhoto(photos[prevIndex]);
        setSelectedPhotoIndex(prevIndex);
    };

    const scrollUp = () => {
        if (thumbnailsOverContainerRef.current) {
            thumbnailsOverContainerRef.current.scrollBy({
                top: -700,
                behavior: 'smooth' // плавная прокрутка
            });
        }
    };

    const scrollDown = () => {
        if (thumbnailsOverContainerRef.current) {
            thumbnailsOverContainerRef.current.scrollBy({
                top: 700,
                behavior: 'smooth' // плавная прокрутка
            });
        }
    };

    // Determine the number of photos to show in the smaller thumbnails
    const thumbnailsToShow = photos.slice(0, maxThumbnails); // Excluding the main photo
    const extraPhotosCount = photos.length - maxThumbnails;

    const handleCopy = () => {
        const textToCopy = car.vin; // Текст, который нужно скопировать

        // Создаем временный элемент input для копирования текста
        const input = document.createElement('input');
        input.value = textToCopy;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);

        // Показываем уведомление
        toast.success(t('vin_copy'));
    };

    function formatNumber(number) {
        return number.toLocaleString('en-US');
    }

    const handleClick = async () => {
        let method = null;
        if (addWatch) {
            method = 'DELETE';
        } else {
            method = 'POST';
        }

        const carData = {
            email: localStorage.getItem('email'),
            origin_id: car.origin_id,
        }
        const response = await fetch('/api/watchlist', {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(carData)
        })
        if (response.ok) {
            const data = await response.json();
            dispatch(clearWatchlist());
            data.watchlist.map(car => (
                dispatch(addVehicle(car))
            ))
            setAddWatch(!addWatch);
        }
    };

    return (
        <div className='vehicleOpen'>
            <Link to='/auction' >
                <button className='backPage'>
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="#0D6efd" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_167_21481)">
                            <path d="M2.63423 0.364572C2.90099 0.0918141 3.33349 0.0918141 3.60025 0.364572C3.86701 0.637331 3.86701 1.07956 3.60025 1.35232L2.20481 2.77914H11.3142C11.6914 2.77914 11.9973 3.09184 11.9973 3.47758C11.9973 3.86332 11.6914 4.17602 11.3142 4.17602H2.18051L3.60025 5.62769C3.86701 5.90045 3.86701 6.34267 3.60025 6.61543C3.33349 6.8882 2.90099 6.8882 2.63423 6.61543L0.302056 4.23082C-0.0980819 3.82167 -0.0980806 3.15833 0.302056 2.74919L2.63423 0.364572Z" />
                        </g>
                    </svg>
                    <p>{t('browse')}</p>
                </button>
            </Link>
            <p className="launchedDate">{formatLaunchDate(car.start_time)}</p>
            <div className="mainInformation">
                <div className="photo-gallery">
                    <div className="main-photo" onClick={() => setOpen(true)}>
                        <img src={mainPhoto} alt="Main" />
                    </div>
                    {car.photos &&
                        <div className="thumbnail-container" ref={thumbnailsContainerRef}>
                            {thumbnailsToShow.map((photo, index) => (
                                <div
                                    key={index}
                                    className={`thumbnail ${selectedPhotoIndex === index ? 'selected' : ''}`}
                                    onClick={() => index < maxThumbnails - 1 ? handleThumbnailClick(photo, index) : setOpen(true)} // Последняя фотография не имеет onClick
                                >
                                    <img src={photo} alt={`Thumbnail ${index + 1}`}></img>
                                    {index === maxThumbnails - 1 && extraPhotosCount > 0 && (
                                        <div className="extra-overlay" onClick={() => setOpen(true)}>
                                            +{extraPhotosCount}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    }
                    {open &&
                        <div className="galleryFullScreen">
                            <div className="galleryscroll">
                                <svg
                                    style={{ paddingBottom: '20px' }}
                                    onClick={scrollUp}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 15L12 9L18 15"
                                        stroke="#FFFFFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                {photos &&
                                    <div className="thumbnail-container-over" ref={thumbnailsOverContainerRef}>
                                        {photos.map((photo, index) => (
                                            <div
                                                key={index}
                                                className={`thumbnail ${selectedPhotoIndex === index ? 'selectedOver' : ''}`}
                                                onClick={() => handleThumbnailClick(photo, index)} // Последняя фотография не имеет onClick
                                            >
                                                <img src={photo} alt={`Thumbnail ${index + 1}`}></img>
                                            </div>
                                        ))}
                                    </div>
                                }
                                <svg
                                    style={{ paddingTop: '20px' }}
                                    onClick={scrollDown}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 9L12 15L18 9"
                                        stroke="#FFFFFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="carousel">
                                <svg
                                    className='closeGallery'
                                    onClick={() => setOpen(false)}
                                    width="40"
                                    height="40"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6 6L18 18"
                                        stroke="#FFFFFF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                    <path
                                        d="M6 18L18 6"
                                        stroke="#FFFFFF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                    />
                                </svg>
                                <svg
                                    className='paginator'
                                    onClick={leftPaginationClick}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M15 6L9 12L15 18"
                                        stroke="#FFFFFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <div className='mainPhotoCarousel'>
                                    <img src={mainPhoto} alt="Main" />
                                </div>
                                <svg
                                    className='paginator'
                                    onClick={rightPaginationClick}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9 6L15 12L9 18"
                                        stroke="#FFFFFF"
                                        strokeWidth="3"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                            </div>
                        </div>
                    }
                </div>
                <div className="textInformation">
                    <div className="head">
                        <p>{`${car.trim} | VIN: ${car.vin}`}<span onClick={handleCopy}><img src={copy} alt="copy" />{t('copy')}</span></p>
                        <ToastContainer t={t} />
                        <div className='headBot'>
                            <div className='carYear'>{car.year}</div>
                            <h2>{car.model.make.name} {car.model.name}</h2>
                        </div>
                    </div>
                    <div className="middleTextInformation">
                        <div className="leftColumn">
                            <div className="infoPoint">
                                <p>{t('odometer')}</p>
                                <h2>{car.odometr} {t('km')}</h2>
                            </div>
                            <div className="infoPoint">
                                <p>{t('expected')}</p>
                                <h2>{car.is_in_stock ? 'In stock' : 'date.expected'}</h2>
                            </div>
                        </div>
                        <div className="rightColumn">
                            <div className="infoPoint">
                                <p>{t('powertrain')}</p>
                                <h2>{car.transmission.name}</h2>
                            </div>
                            <div className="infoPoint">
                                <p>{t('full_specs')}</p>
                                <a href="#full">{t('view_full')}</a>
                            </div>
                        </div>
                    </div>
                    <div className="bottomTextInformation">
                        <AddWatch handleClick={handleClick} watch={addWatch} />
                        <div className="bidsPrice">
                            {car.current_bid ?
                                <>
                                    <p>33 {t('bids')} |</p>
                                    <h2>${formatNumber(car.current_bid)}</h2>
                                </>
                                :
                                <h2 style={{ fontSize: '14px' }}>{t('no_bids')}</h2>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="history">
                <div className="historyHeader">
                    <div className="nameBlock">
                        <div className="summary">
                            <h3>{t('history_sum')}</h3>
                            <div className="summaryCount">{'carfax'}</div>
                        </div>
                        <p>{t('view_carfax')}</p>
                        {width <= 767 && <img src={carfaximg} alt="carfax" />}
                    </div>
                    <div className="carfax">
                        {width > 767 && <p>{t('view')}</p>}
                        {width > 767 && <img src={carfaximg} alt="carfax" />}
                    </div>
                </div>
                <div className="historyItems">
                    <div className="historyItem">
                        <h4>{t('brand')} / {t('status')}</h4>
                        {/* {status && status.length > 0 ? (
                            <ul>
                                {status.map((item, index) => (
                                    <li key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        ) : ( */}
                        <p className='noneRep'>{t('none_rep')}</p>
                        {/* )} */}
                    </div>
                    <div className="historyItem">
                        <h4>{t('add_details')}</h4>
                        {/* {details && details.length > 0 ? (
                            <ul>
                                {details.map((item, index) => (
                                    <li key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        ) : ( */}
                        <p className='noneRep'>{t('none_rep')}</p>
                        {/* )} */}
                    </div>
                    <div className="historyItem">
                        <h4>{t('previous')}</h4>
                        {/* {previous && previous.length > 0 ? (
                            <ul>
                                {previous.map((item, index) => (
                                    <li key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        ) : ( */}
                        <p className='noneRep'>{t('none_rep')}</p>
                        {/* )} */}
                    </div>
                    <div className="historyItem">
                        <h4>{t('damage')} / {t('other')}</h4>
                        {/* {damage && damage.length > 0 ? (
                            <ul>
                                {damage.map((item, index) => (
                                    <li key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        ) : ( */}
                        <p className='noneRep'>{t('none_rep')}</p>
                        {/* )} */}
                    </div>
                </div>
            </div>
            <div className="history">
                <div className="historyHeader">
                    <div className="nameBlock">
                        <div className="summary">
                            <h3>{t('condition')}</h3>
                            <div className="summaryCount">{'carfax'}</div>
                        </div>
                    </div>
                </div>
                <div className="historyItems">
                    <div className="conditionItem">
                        <img src={safety} alt="safety" />
                        <h4>{t('safe')}</h4>
                        <DamageList damages={safetyDamages} categoryId={4} />
                    </div>
                    <div className="conditionItem">
                        <img src={mechanical} alt="mechanical" />
                        <h4>{t('mech')}</h4>
                        <DamageList damages={mechanicalDamages} categoryId={3} />
                    </div>
                    <div className="conditionItem">
                        <img src={exterior} alt="exterior" />
                        <h4>{t('exterior')}</h4>
                        <DamageList damages={exteriorDamages} categoryId={2} />
                    </div>
                    <div className="conditionItem">
                        <img src={interior} alt="interior" />
                        <h4>{t('interior')}</h4>
                        <DamageList damages={interiorDamages} categoryId={1} />
                    </div>
                </div>
                <hr className='hr' />
                <div className="inspection">
                    <div className="inspectionHeader">
                        <img src={note} alt="note" />
                        <div className="historyItem">
                            <h4>{t('inspect')}</h4>
                            <p className="noneRep">{t('read')}</p>
                        </div>
                    </div>
                    <div className="notes">
                        {car.details.notes}
                    </div>
                </div>
            </div>
            <div className="history" id="full">
                <div className="nameBlock">
                    <div className="summary">
                        <h3>{t('full_specs')}</h3>
                    </div>
                    <p>{t('view_carfax')}</p>
                </div>
                <div className="rows">
                    <div className="row">
                        <div className="infoPoint">
                            <p>{t('year')}</p>
                            <h2>{car.year}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('make')}</p>
                            <h2>{car.model.make.name}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('model')}</p>
                            <h2>{car.model.name}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('trim')}</p>
                            <h2>{car.trim}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('powertrain')}</p>
                            <h2>{car.drive_train.name}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('odometer')}</p>
                            <h2>{car.odometr}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('exin')}</p>
                            <h2>{car.details.exterior_color}/{car.details.interior_color}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>{t('seats')}</p>
                            <h2>{car.details.seats}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>VIN</p>
                            <h2>{car.vin}</h2>
                        </div>
                        <div className="infoPoint">
                            <p>Trade ID</p>
                            <h2>{car.origin_id}</h2>
                        </div>
                    </div>
                </div>
                <div className="featuresBlock">
                    <p>{t('vehicle_features')}</p>
                    <div className="features">
                        {car.options.map((item) => (
                            <div className="feature" key={item.option.id}>
                                <img src={tick} alt={`feature_${item.option.id}`} />
                                <p>{item.option.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'
import './browseVehicles.css'

export default function BrowseVehicles({ t }) {
    return (
        <Link to='auction'>
            <button className='browseVehicles'>
                <svg width="16" height="17" viewBox="0 0 16 17" fill="#0A1B5F" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 5.83332C14.5 6.10666 14.2733 6.33332 14 6.33332H2C1.72667 6.33332 1.5 6.10666 1.5 5.83332C1.5 5.55999 1.72667 5.33332 2 5.33332H2.68L2.93333 4.12666C3.17333 2.95999 3.67333 1.88666 5.66 1.88666H10.34C12.3267 1.88666 12.8267 2.95999 13.0667 4.12666L13.32 5.33332H14C14.2733 5.33332 14.5 5.55999 14.5 5.83332Z" />
                    <path d="M14.7866 9.60665C14.6866 8.50665 14.3933 7.33331 12.2533 7.33331H3.74664C1.60664 7.33331 1.31998 8.50665 1.21331 9.60665L0.839976 13.6666C0.793309 14.1733 0.959976 14.68 1.30664 15.06C1.65998 15.4466 2.15998 15.6666 2.69331 15.6666H3.94664C5.02664 15.6666 5.23331 15.0466 5.36664 14.64L5.49998 14.24C5.65331 13.78 5.69331 13.6666 6.29331 13.6666H9.70664C10.3066 13.6666 10.3266 13.7333 10.5 14.24L10.6333 14.64C10.7666 15.0466 10.9733 15.6666 12.0533 15.6666H13.3066C13.8333 15.6666 14.34 15.4466 14.6933 15.06C15.04 14.68 15.2066 14.1733 15.16 13.6666L14.7866 9.60665ZM5.99998 11H3.99998C3.72664 11 3.49998 10.7733 3.49998 10.5C3.49998 10.2266 3.72664 9.99998 3.99998 9.99998H5.99998C6.27331 9.99998 6.49998 10.2266 6.49998 10.5C6.49998 10.7733 6.27331 11 5.99998 11ZM12 11H9.99998C9.72664 11 9.49998 10.7733 9.49998 10.5C9.49998 10.2266 9.72664 9.99998 9.99998 9.99998H12C12.2733 9.99998 12.5 10.2266 12.5 10.5C12.5 10.7733 12.2733 11 12 11Z" />
                </svg>
                <p>{t('browse')}</p>
            </button>
        </Link>
    )
}

import React, { useEffect, useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../assets/img/logo.svg'
import BrowseVehicles from '../../../buttons/BrowseVehicles/BrowseVehicles'
// import Notifications from '../../../buttons/Notifications/Notifications'
import Whatchlist from '../../../buttons/Whatchlist/Whatchlist'
import Profilebtn from '../../../buttons/Profilebtn/Profilebtn'
import SwitchLanguage from '../../../buttons/SwitchLanguage/SwitchLanguage'
import LogInBtn from '../../../buttons/LogInBtn/LogInBtn'
import SignUpBtn from '../../../buttons/SignUpBtn/SignUpBtn'
import login from '../../../assets/img/login.svg'
import './header.css'

export default function Header({ t, i18n, textBtn, userLoggedIn, setLogin }) {
  const location = useLocation();
  const showButtonsAuth = location.pathname === '/';
  const [isClicked, setIsClicked] = useState(false);
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {

    const fetchData = async (email) => {
      const response = await fetch('/api/user?email=' + email);
      if (response.ok) {
        const data = await response.json();
        setName(data.name);
        localStorage.setItem('user', JSON.stringify(data));
        // console.log(data);
      }
    }

    const email = localStorage.getItem('email');
    if (email) {
      fetchData(email);
    }

  }, [userLoggedIn]);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


  const handleClick = () => {
    setTimeout(() => {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 600); // Убираем тень через 150 мс
    }, 150); // Задержка 600 мс перед добавлением тени
  };
  return (
    <div className='header'>
      <Link to='/' className='logo'>
        <img src={logo} alt="sunmax" />
      </Link>
      {userLoggedIn ?
        <>
          <div className='centerButtons'>
            <BrowseVehicles t={t} />
          </div>
          <div className='endButtons'>
            {/* <Notifications t={t} /> */}
            <Whatchlist t={t} />
            <Profilebtn t={t} i18n={i18n} setLogin={setLogin} name={name} />
          </div>
        </>
        :
        <div className="headerButtons">
          <SwitchLanguage t={t} i18n={i18n} />
          {showButtonsAuth && (
            window.innerWidth <= 1024 ?
              <div ref={containerRef}>
                <button className='loginSvg' onClick={() => setOpen(!open)} ref={containerRef}>
                  <img src={login} alt="login"/>
                </button>
                {open &&
                  <div>
                    <svg className="loginMenuSvg" width="20" height="10" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
                      <polygon points="10,0 20,10 0,10" fill="white" />
                    </svg>
                    <div className='loginMenu'>
                      <LogInBtn t={t} />
                      {/* <SignUpBtn t={t} textBtn={textBtn} onClick={handleClick} isClicked={isClicked} /> */}
                    </div>
                  </div>
                }
              </div>
              :
              <>
                <LogInBtn t={t} />
                <SignUpBtn t={t} textBtn={textBtn} onClick={handleClick} isClicked={isClicked} styleHeader={{padding: '8px 14px'}} />
              </>
          )}
        </div>
      }
    </div>
  )
}

import React, { useState, useEffect, useMemo } from 'react';
import './rangeFilter.css';

// Функция debounce для задержки выполнения функции
function debounce(func, delay) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}

export default function RangeFilter({ label, unit, filter, setFilter, width }) {
  let min = null;
  let max = null;

  if (label === 'Odometer') {
    min = 0;
    max = 200000;
  } else {
    min = 1980;
    max = 2025;
  }

  // Локальные состояния для начальных и конечных значений диапазона
  const [tempRangeSt, setTempRangeSt] = useState(min);
  const [tempRangeEn, setTempRangeEn] = useState(max);

  // Обновление состояний на основе пропсов filter (при инициализации или сбросе фильтра)
  useEffect(() => {
    if (label === 'Odometer') {
      setTempRangeSt(filter.odometer_st !== null ? filter.odometer_st : min);
      setTempRangeEn(filter.odometer_en !== null ? filter.odometer_en : max);
    } else {
      setTempRangeSt(filter.year_st !== null ? filter.year_st : min);
      setTempRangeEn(filter.year_en !== null ? filter.year_en : max);
    }
  }, [filter, label, min, max]);

  // Функция для изменения начального значения
  const handleMinChange = (e) => {
    const newMin = Number(e.target.value);
    setTempRangeSt(newMin);
  };

  // Функция для изменения конечного значения
  const handleMaxChange = (e) => {
    const newMax = Number(e.target.value);
    setTempRangeEn(newMax);
  };

  // Обновление фильтра с задержкой
  const updateFilterWithDebounce = useMemo(() =>
    debounce((newMin, newMax) => {
      if (label === 'Odometer') {
        setFilter((prevFilter) => ({
          ...prevFilter,
          odometer_st: newMin === min ? null : newMin,
          odometer_en: newMax === max ? null : newMax,
        }));
      } else {
        setFilter((prevFilter) => ({
          ...prevFilter,
          year_st: newMin === min ? null : newMin,
          year_en: newMax === max ? null : newMax,
        }));
      }
    }, 500), // Задержка 500 мс
    [label, min, max, setFilter]
  );

  // Отслеживание изменений диапазона и применение фильтра
  useEffect(() => {
    updateFilterWithDebounce(tempRangeSt, tempRangeEn);
  }, [tempRangeSt, tempRangeEn, updateFilterWithDebounce]);

  // Функция форматирования числа с разделением тысяч
  const formatNumberWithCommas = (number) => {
    return number.toLocaleString('ru-Ru');
  };

  const formatValue = (value) => {
    return label === 'Odometer' ? formatNumberWithCommas(value) : value;
  };

  return (
    <div className="range-filter">
      <div className="range-values">
        <h4>{label}</h4>
        <span>{`${formatValue(tempRangeSt)} - ${formatValue(tempRangeEn)} ${
          unit ? unit : ''
        }`}</span>
      </div>
      <div className="slider-container">
        <input
          type="range"
          min={min}
          max={max}
          value={tempRangeSt}
          onChange={handleMinChange}
          className="slider slider-min"
          step="1"
        />
        <input
          type="range"
          min={min}
          max={max}
          value={tempRangeEn}
          onChange={handleMaxChange}
          className="slider slider-max"
          step="1"
        />
        <div className="slider-track"></div>
        <div
          className="slider-range"
          style={{
            left: `${((tempRangeSt - min) / (max - min)) * 100}%`,
            right: `${100 - ((tempRangeEn - min) / (max - min)) * 100}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

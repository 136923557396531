import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import './skelet.css'

export default function Skelet({ t, i18n, textBtn, userLoggedIn, setLogin, width }) {
  const location = useLocation()
  return (
    <div className='container'>
        <Header t={t} i18n={i18n} textBtn={textBtn} userLoggedIn={userLoggedIn} setLogin={setLogin}/>
        <main className="content" style={{alignItems: (width <= 1024 && location.pathname === '/') && 'center'}}>
          <Outlet />
        </main>
        <Footer t={t}/>
    </div>
  )
}

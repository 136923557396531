import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './match.css';
import AddWatch from '../../buttons/AddWatch/AddWatch';
import { useDispatch, useSelector } from 'react-redux';
import { addVehicle, clearWatchlist } from '../../store';


// Компонент загрузки информации об авто
const Dot = ({ begin }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="0" fill="currentColor">
                <animate attributeName="r" begin={begin} calcMode="spline" dur="1.5s" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="0;2;0;0" />
            </circle>
        </svg>
    )
}

// Компонент загрузки картинки
const Circle = ({ diametr }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={diametr} height={diametr} viewBox="0 0 24 24">
            <g fill="black">
                <circle cx="12" cy="3.5" r="1.5">
                    <animateTransform attributeName="transform" calcMode="discrete" dur="2.4s" repeatCount="indefinite" type="rotate" values="0 12 12;90 12 12;180 12 12;270 12 12" />
                    <animate attributeName="opacity" dur="0.6s" repeatCount="indefinite" values="1;1;0" />
                </circle>
                <circle cx="12" cy="3.5" r="1.5" transform="rotate(30 12 12)">
                    <animateTransform attributeName="transform" begin="0.2s" calcMode="discrete" dur="2.4s" repeatCount="indefinite" type="rotate" values="30 12 12;120 12 12;210 12 12;300 12 12" />
                    <animate attributeName="opacity" begin="0.2s" dur="0.6s" repeatCount="indefinite" values="1;1;0" />
                </circle>
                <circle cx="12" cy="3.5" r="1.5" transform="rotate(60 12 12)">
                    <animateTransform attributeName="transform" begin="0.4s" calcMode="discrete" dur="2.4s" repeatCount="indefinite" type="rotate" values="60 12 12;150 12 12;240 12 12;330 12 12" />
                    <animate attributeName="opacity" begin="0.4s" dur="0.6s" repeatCount="indefinite" values="1;1;0" />
                </circle>
            </g>
        </svg>
    )
}


// Компонент для отображения одной машины
const Car = ({ t, car, column, loading, width }) => {

    const [addWatch, setAddWatch] = useState(false)
    const dispatch = useDispatch();

    const watchlist = useSelector(state => state.watchlist);

    function formatEventEndDate(timestamp) {
        const now = new Date();
        const endDate = new Date(timestamp);
        const diffInMs = endDate - now;

        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

        if (diffInDays >= 2) {
            const hrsLeft = diffInDays * 24 + diffInHours % 24; // Общие часы, оставшиеся до события
            return `${hrsLeft}hrs left`;
        } else if (diffInHours >= 1) {
            return `${diffInHours}hrs left`;
        } else if (diffInMinutes > 0) {
            return `${diffInMinutes}min left`;
        } else {
            // Если дата истекла или остается менее минуты
            const day = String(endDate.getDate()).padStart(2, '0');
            const month = String(endDate.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0 в JavaScript
            const year = endDate.getFullYear();
            return `${day}.${month}.${year} ${t('left')}`;
        }
    }

    useEffect(() => {
        if (!loading) {
            if (car && car.origin_id) { // Проверяем, что car и car.origin_id существуют
                if (Array.isArray(watchlist)) { // Проверяем, что watchlist является массивом
                    const isInWatchlist = watchlist.some(item => {
                        return item.vehicle && item.vehicle.origin_id === car.origin_id; // Проверяем, что item.vehicle существует
                    });
                    setAddWatch(isInWatchlist);
                } else {
                    console.error('Ошибка: watchlist не является массивом:', watchlist);
                }
            } else {
                console.error('Ошибка: отсутствует car или car.origin_id:', car);
            }
        }
    }, [watchlist, car, loading]);


    const handleClick = async (event) => {
        let method = null;
        if (addWatch) {
            method = 'DELETE';
        } else {
            method = 'POST';
        }

        const carData = {
            email: localStorage.getItem('email'),
            origin_id: car.origin_id,
        }
        const response = await fetch('/api/watchlist', {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(carData)
        })
        if (response.ok) {
            const data = await response.json();
            dispatch(clearWatchlist());
            data.watchlist.map(car => (
                dispatch(addVehicle(car))
            ))
            setAddWatch(!addWatch);
        }
    };

    return (
        <div className="car" style={column ? undefined :
            {
                flexDirection: 'column',
                maxWidth: '20rem',
            }
        }>
            {width > 767 &&
                <Link to={`/auction/${car.origin_id}`} key={car.origin_id} style={{ color: 'inherit' }}>
                    <div className="carPhoto">
                        {!loading && (<p className="timeLeft">{formatEventEndDate(car.end_time)}</p>)}
                        {!loading ? (
                            <img src={car.main_photo} alt="Car" style={column ? undefined : {
                                borderBottomLeftRadius: '0',
                                borderTopRightRadius: '12px',
                                width: '100%',
                                height: '206px',
                            }} />
                        ) : (
                            <div className='imgLoading' style={column ? {
                                width: '305px',
                                height: '220px',
                            } : {
                                width: '402px',
                                height: '206px',
                            }}>
                                <Circle diametr={"64"} />
                            </div>

                        )}

                    </div>
                </Link>
            }
            <div className="carInformation" style={column ? undefined : {
                width: 'unset'
            }}>
                {loading ? (
                    <div className="carHeader">
                        <div className="carName">
                            <h2><Dot begin={0} /><span><Dot begin={0.33} /></span><Dot begin={0.67} /></h2>
                            <h3><Dot begin={0.67} /><span><Dot begin={0.33} /> <Dot begin={0} /></span></h3>
                        </div>
                        <div className="bidsPrice" style={column ? undefined : { flexDirection: 'column' }}>
                            <h2 style={{ fontSize: '14px' }}><Dot begin={0} /><Dot begin={0.33} /><Dot begin={0.67} /></h2>
                        </div>

                    </div >
                ) : (
                    <Link to={`/auction/${car.origin_id}`} key={car.origin_id}>
                        <div className="carHeader">
                            {width <= 767 &&
                                <div className="carPhoto">
                                    {!loading && (<p className="timeLeft">{formatEventEndDate(car.end_time)}</p>)}
                                    {!loading ? (
                                        <img src={car.main_photo} alt="Car" style={column ? undefined : {
                                            borderBottomLeftRadius: '0'
                                        }} />
                                    ) : (
                                        <div className='imgLoading' style={column ? {
                                            width: '305px',
                                            height: '220px',
                                        } : {
                                            width: '402px',
                                            height: '206px',
                                        }}>
                                            <Circle diametr={"64"} />
                                        </div>

                                    )}

                                </div>
                            }
                            <div className="carName">
                                <h2>{car.year} <span>{car.model.make.name}</span> {car.model.name}</h2>
                                { width > 767 &&
                                <div className="bidsPrice" style={column ? { display: 'none' } : { display: 'flex' }}>
                                    {car.current_bid ?
                                        <>
                                            <p>{car.increment_bid} {t('bids')} {width > 767 && '|'}</p>
                                            <h2>${car.current_bid}</h2>
                                        </>
                                        :
                                        <h2 style={{ fontSize: width > 767 ? '14px' : '0.6rem', textAlign: 'right' }}>{t('no_bids')}</h2>
                                    }
                                </div>
                                }
                                <h3>{car.odometr} {car.odometr_unit} <span>| {car.drive_train.name} | {car.trim}</span></h3>
                                {width > 767 &&
                                    <div className="is">
                                        {car.is_as_is && <p className='as'>{t('as_is')}</p>}
                                        {car.is_disclosures && <p className='disclosures'>{t('disclouseres')}</p>}
                                        {car.is_reserve_set && <p className='floor'>{t('floor_set')}</p>}
                                        {car.is_in_stock && <p className='stock'>{t('in_stock')}</p>}
                                    </div>
                                }
                            </div>
                            <div className="bidsPrice" style={column || width <= 767 ? { display: 'flex' } : { display: 'none' }}>
                                {car.current_bid ?
                                    <>
                                        <p>{car.count_bid} {t('bids')} {width > 767 && '|'}</p>
                                        <h2>${car.current_bid}</h2>
                                    </>
                                    :
                                    <h2 style={{ fontSize: width > 767 ? '14px' : '0.6rem', textAlign: 'right' }}>{t('no_bids')}</h2>
                                }
                            </div>
                        </div >
                    </Link>
                )}
                <div className="carFooter">
                    {/* <button className='minBid'>
                        Minimum bid <span>${start_price}</span>
                    </button> */}
                    {!loading ? (
                        width <= 767 ?
                            <>
                                <div className="is">
                                    {car.is_as_is && <p className='as'>{t('as_is')}</p>}
                                    {car.is_disclosures && <p className='disclosures'>{t('disclouseres')}</p>}
                                    {car.is_reserve_set && <p className='floor'>{t('floor_set')}</p>}
                                    {car.is_in_stock && <p className='stock'>{t('in_stock')}</p>}
                                </div>
                                <AddWatch handleClick={handleClick} watch={addWatch} width={width} />
                            </>
                            :
                            <AddWatch handleClick={handleClick} watch={addWatch} />
                    ) : (
                        <Circle diametr={40} />
                    )}
                </div>
            </div >
        </div >
    );
};

export default function Match({ t, nameFilter, column, cars, loading, width }) {

    return (
        <div className='matchContainer'>
            {loading ? (
                <>
                    <h2 className='carsCount'>{t('loading')}..</h2>
                    <div className='carsContainer'>
                        <Car car={null} column={column} loading={true} />
                        <Car car={null} column={column} loading={true} />
                        <Car car={null} column={column} loading={true} />
                        <Car car={null} column={column} loading={true} />
                    </div>
                </>
            ) : (
                <>
                    <h2 className='carsCount'>{cars.length} {t('in')} {nameFilter}</h2>
                    <div className='carsContainer'>
                        {cars.map(car => (
                            <Car t={t} car={car} column={column} loading={false} width={width} key={car.origin_id} />
                        ))}
                    </div>
                </>
            )}
        </div >
    );
}

import React from 'react'
import './vehiclesWave.css'

const columns = 9;
const imagesPerColumn = 3;

const VehiclesWave = ({ images }) => {
  const getInitialOffset = (columnIndex) => {
    if (columnIndex < 4) {
      return columnIndex * 140; // Каждый следующий столбец ниже предыдущего на 140px
    } else {
      return (8 - columnIndex) * 140; // Начиная с 5-го столбца (с индексом 4), столбцы поднимаются
    }
  };

  const generateColumns = () => {
    return Array.from({ length: columns }, (_, columnIndex) => (
      <div
        key={columnIndex}
        className="wave-column"
        style={{
          '--initial-offset': `${getInitialOffset(columnIndex)}px`, // Устанавливаем начальное положение через CSS-переменные
          animationDelay: `${columnIndex * 0.2}s`, // Устанавливаем задержку для анимации
        }}
      >
        {Array.from({ length: imagesPerColumn }, (_, imageIndex) => (
          <img
            key={imageIndex}
            src={images[columnIndex * imagesPerColumn + imageIndex]}
            alt={`vehicles ${columnIndex * imagesPerColumn + imageIndex}`}
            className="wave-image"
          />
        ))}
      </div>
    ));
  };

  return <div className="wave-container">{generateColumns()}</div>;
};


export default VehiclesWave;
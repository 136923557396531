import React, { useState } from 'react'
import global from '../../assets/img/global.svg'
import './switchLanguage.css'

export default function SwitchLanguage({ auth, i18n }) {
    const [language, setLanguage] = useState(i18n.language);
  
    // Обработчик для смены языка через селект
    const handleChangeSelect = (e) => {
      const newLanguage = e.target.value;
      setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
    };
  
    // Обработчик для смены языка через кнопку
    const handleChangeButton = () => {
      const newLanguage = language === 'en' ? 'ru' : 'en'; // Переключаем язык
      setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
    };

  return (
    <>
    { auth ? 
    <button className="menu-item" onClick={handleChangeButton}>
        <img src={global} alt="Switch language" />
        <p>{language === 'en' ? 'Switch to Russian' : 'Switch to English'}</p>
    </button>
    :
    <select 
    className='nonAuthBtn'
    value={language}
    onChange={handleChangeSelect}
    >
        <option value="en">EN</option>
        <option value="ru">RU</option>
    </select>
    }
    </>
  )
}

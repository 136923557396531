import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import './loginbtn.css'

export default function LoginBtn({ t }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setTimeout(() => {
      setIsClicked(true);
      setTimeout(() => {
        setIsClicked(false);
      }, 600); // Убираем тень через 150 мс
    }, 150); // Задержка 600 мс перед добавлением тени
  };
  return (
    <Link to='/login'>
      <button
        className={`logInBtn ${isClicked ? 'clicked' : ''}`}
        onClick={handleClick}
      >
        {t('Log in')}
      </button>
    </Link>
  )
}

import React from 'react'
import './mainFilter.css'
import { Icon } from '@iconify/react/dist/iconify.js';

export default function MainFilter({ activeIndex, setActiveIndex, column, setColumn, setFilter, t, width, setOpenFilter, openFilter }) {
    // const [setSelectedOption] = useState('');

    const handleButtonClick = (index) => {
        setActiveIndex(index);
        setFilter(prevFilter => ({
            ...prevFilter,
            status: index === 1 ? 'active' : 'upcoming'
        }));
    };

    // const handleChange = (event) => {
    //     setSelectedOption(event.target.value);
    // };

    const changeArrange = () => {
        setColumn(!column)
    }
    return (
        <div className='mainFilter'>
            <div className="filterButtons">
                <button
                    className={activeIndex === 0 ? 'active' : ''}
                    onClick={() => handleButtonClick(0)}
                >
                    <h2>{t('upcomming')}</h2>
                    {width >= 1200 && <p>{t('preview')}</p>}
                
                </button>
                <button
                    className={activeIndex === 1 ? 'active' : ''}
                    onClick={() => handleButtonClick(1)}
                >
                    <h2>{t('active')}</h2>
                    {width >= 1200 && <p>{t('always')}</p>}
                </button>
                {/* <button
                    className={activeIndex === 2 ? 'active' : ''}
                    onClick={() => handleButtonClick(2)}
                >
                    <h2>Simulcast</h2>
                    <p>Runlists & live auction events</p>
                </button>
                <button
                    className={activeIndex === 3 ? 'active' : ''}
                    onClick={() => handleButtonClick(3)}
                >
                    <h2>DealerBlock</h2>
                    <p>Bid & buy now listings</p>
                </button>
                <button
                    className={activeIndex === 4 ? 'active' : 'redButton'}
                    onClick={() => handleButtonClick(4)}
                >
                    <h2>Eblock</h2>
                    <p>Front-line dealer vehicles</p>
                </button> */}
            </div>
            <div className="sorting">
                {/* <select
                    name="sort"
                    id="sortselect"
                    value={selectedOption}
                    onChange={handleChange}
                    className={selectedOption === '' ? 'placeholder' : ''}
                >
                    <option value="" disabled hidden className='disabledOption'>{t('select_options')}</option>
                    <option value="option1">{t('option1')}</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                </select> */}
                {width <= 1200 && <Icon icon="ion:filter" width="20px" height="20px" className='filterOpener' style={{color: 'black'}} onClick={() => setOpenFilter(!openFilter)} />}
                {width > 768 && <div className='arranging'>
                    {t('view_as')}:
                    <button className='svgButton' onClick={changeArrange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className='path' d="M0.5 3.5C0.5 2.11929 1.61929 1 3 1H21C22.3807 1 23.5 2.11929 23.5 3.5V21.5C23.5 22.8807 22.3807 24 21 24H3C1.61929 24 0.5 22.8807 0.5 21.5V3.5Z" stroke={column ? "#0061FF" : "#D7D7D7"} style={{ fill: 'none' }} />
                            <path d="M21 3.5H3V6.5H21V3.5Z" fill={column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 8.5H3V11.5H21V8.5Z" fill={column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 13.5H3V16.5H21V13.5Z" fill={column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 18.5H3V21.5H21V18.5Z" fill={column ? "#0061FF" : "#D7D7D7"} />
                        </svg>
                    </button>
                    <button className='svgButton' onClick={changeArrange}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className='path' d="M0.5 3.5C0.5 2.11929 1.61929 1 3 1H21C22.3807 1 23.5 2.11929 23.5 3.5V21.5C23.5 22.8807 22.3807 24 21 24H3C1.61929 24 0.5 22.8807 0.5 21.5V3.5Z" stroke={!column ? "#0061FF" : "#D7D7D7"} style={{ fill: 'none' }} />
                            <path d="M11 3.5H3V6.5H11V3.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 3.5H13V6.5H21V3.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M11 8.5H3V11.5H11V8.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 8.5H13V11.5H21V8.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M11 13.5H3V16.5H11V13.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 13.5H13V16.5H21V13.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M11 18.5H3V21.5H11V18.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                            <path d="M21 18.5H13V21.5H21V18.5Z" fill={!column ? "#0061FF" : "#D7D7D7"} />
                        </svg>
                    </button>
                </div>}
            </div>
        </div>
    )
}

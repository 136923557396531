import React, { useState, useRef, useEffect } from "react";
import './whatchlist.css';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { store } from "../../store";

export default function Whatchlist({ t }) {
  const vehiclesWatch = useSelector(state => state.watchlist);
  const [watchList, setWatchList] = useState(vehiclesWatch);
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentWatchlist = store.getState().watchlist;
    setWatchList(currentWatchlist);
  }, [vehiclesWatch, watchList]);


  function formatNumber(number) {
    if (number <= 100 && number !== undefined) {
      return number.toString();
    }
    return number.toLocaleString('en-US');
  }

  return (
    <div ref={containerRef}>
      <button className="whatchlist" onClick={() => setOpen(!open)} ref={containerRef}>
        {watchList.length === 0 ?
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className='heart' d="M20.68 13.91V13.9C19.8 12.97 18.57 12.4 17.2 12.4C14.55 12.4 12.4 14.55 12.4 17.2C12.4 18.43 12.87 19.55 13.63 20.4C14.51 21.38 15.78 22 17.2 22C19.85 22 22 19.85 22 17.2C22 15.93 21.5 14.77 20.68 13.91ZM18.99 18.01H17.95V19.1C17.95 19.51 17.61 19.85 17.2 19.85C16.79 19.85 16.45 19.51 16.45 19.1V18.01H15.41C14.99 18.01 14.66 17.68 14.66 17.26C14.66 16.85 14.98 16.52 15.4 16.51H16.45V15.51C16.45 15.49 16.45 15.47 16.46 15.45C16.48 15.07 16.81 14.76 17.2 14.76C17.6 14.76 17.93 15.08 17.95 15.48V16.51H18.99C19.41 16.51 19.74 16.85 19.74 17.26C19.74 17.68 19.41 18.01 18.99 18.01Z" fill="#CACACA" />
            <path className='plus' d="M22 8.73001C22 9.92001 21.81 11.02 21.48 12.04C21.42 12.25 21.17 12.31 20.99 12.18C19.9 11.37 18.57 10.94 17.2 10.94C13.73 10.94 10.9 13.77 10.9 17.24C10.9 18.32 11.18 19.38 11.71 20.32C11.87 20.6 11.68 20.96 11.38 20.85C8.97 20.03 4.1 17.04 2.52 12.04C2.19 11.02 2 9.92001 2 8.73001C2 5.64001 4.49 3.14001 7.56 3.14001C9.37 3.14001 10.99 4.02001 12 5.37001C13.01 4.02001 14.63 3.14001 16.44 3.14001C19.51 3.14001 22 5.64001 22 8.73001Z" fill="#CACACA" />
          </svg>
          :
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.2 12.4C14.55 12.4 12.4 14.55 12.4 17.2C12.4 18.1 12.65 18.95 13.1 19.67C13.93 21.06 15.45 22 17.2 22C18.95 22 20.48 21.06 21.3 19.67C21.74 18.95 22 18.1 22 17.2C22 14.55 19.85 12.4 17.2 12.4ZM19.58 16.57L17.02 18.93C16.88 19.06 16.69 19.13 16.51 19.13C16.32 19.13 16.13 19.06 15.98 18.91L14.8 17.73C14.51 17.44 14.51 16.96 14.8 16.67C15.09 16.38 15.57 16.38 15.86 16.67L16.53 17.34L18.56 15.46C18.86 15.18 19.34 15.2 19.62 15.5C19.9 15.81 19.88 16.29 19.58 16.57Z" fill="#0061FF" />
            <path d="M22 8.73001C22 9.92001 21.81 11.02 21.48 12.04C21.42 12.25 21.17 12.31 20.99 12.18C19.9 11.37 18.57 10.94 17.2 10.94C13.73 10.94 10.9 13.77 10.9 17.24C10.9 18.32 11.18 19.38 11.71 20.32C11.87 20.6 11.68 20.96 11.38 20.85C8.97 20.03 4.1 17.04 2.52 12.04C2.19 11.02 2 9.92001 2 8.73001C2 5.64001 4.49 3.14001 7.56 3.14001C9.37 3.14001 10.99 4.02001 12 5.37001C13.01 4.02001 14.63 3.14001 16.44 3.14001C19.51 3.14001 22 5.64001 22 8.73001Z" fill="#F95341" />
          </svg>
        }
        <p className="vehiclesWatch">{watchList.length}</p>
        <p className="nameWatchlist">{t('watchlist')}</p>
      </button>
      {open &&
        <>
          <svg className="menuNoticeSvg" width="20" height="10" viewBox="0 0 20 10" xmlns="http://www.w3.org/2000/svg">
            <polygon points="10,0 20,10 0,10" fill="white" />
          </svg>
          <div className="menuNotice">
            <div className="countNotice">
              {watchList.length === 0 ?
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className='heart' d="M20.68 13.91V13.9C19.8 12.97 18.57 12.4 17.2 12.4C14.55 12.4 12.4 14.55 12.4 17.2C12.4 18.43 12.87 19.55 13.63 20.4C14.51 21.38 15.78 22 17.2 22C19.85 22 22 19.85 22 17.2C22 15.93 21.5 14.77 20.68 13.91ZM18.99 18.01H17.95V19.1C17.95 19.51 17.61 19.85 17.2 19.85C16.79 19.85 16.45 19.51 16.45 19.1V18.01H15.41C14.99 18.01 14.66 17.68 14.66 17.26C14.66 16.85 14.98 16.52 15.4 16.51H16.45V15.51C16.45 15.49 16.45 15.47 16.46 15.45C16.48 15.07 16.81 14.76 17.2 14.76C17.6 14.76 17.93 15.08 17.95 15.48V16.51H18.99C19.41 16.51 19.74 16.85 19.74 17.26C19.74 17.68 19.41 18.01 18.99 18.01Z" fill="#CACACA" />
                  <path className='plus' d="M22 8.73001C22 9.92001 21.81 11.02 21.48 12.04C21.42 12.25 21.17 12.31 20.99 12.18C19.9 11.37 18.57 10.94 17.2 10.94C13.73 10.94 10.9 13.77 10.9 17.24C10.9 18.32 11.18 19.38 11.71 20.32C11.87 20.6 11.68 20.96 11.38 20.85C8.97 20.03 4.1 17.04 2.52 12.04C2.19 11.02 2 9.92001 2 8.73001C2 5.64001 4.49 3.14001 7.56 3.14001C9.37 3.14001 10.99 4.02001 12 5.37001C13.01 4.02001 14.63 3.14001 16.44 3.14001C19.51 3.14001 22 5.64001 22 8.73001Z" fill="#CACACA" />
                </svg>
                :
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.2 12.4C14.55 12.4 12.4 14.55 12.4 17.2C12.4 18.1 12.65 18.95 13.1 19.67C13.93 21.06 15.45 22 17.2 22C18.95 22 20.48 21.06 21.3 19.67C21.74 18.95 22 18.1 22 17.2C22 14.55 19.85 12.4 17.2 12.4ZM19.58 16.57L17.02 18.93C16.88 19.06 16.69 19.13 16.51 19.13C16.32 19.13 16.13 19.06 15.98 18.91L14.8 17.73C14.51 17.44 14.51 16.96 14.8 16.67C15.09 16.38 15.57 16.38 15.86 16.67L16.53 17.34L18.56 15.46C18.86 15.18 19.34 15.2 19.62 15.5C19.9 15.81 19.88 16.29 19.58 16.57Z" fill="#0061FF" />
                  <path d="M22 8.73001C22 9.92001 21.81 11.02 21.48 12.04C21.42 12.25 21.17 12.31 20.99 12.18C19.9 11.37 18.57 10.94 17.2 10.94C13.73 10.94 10.9 13.77 10.9 17.24C10.9 18.32 11.18 19.38 11.71 20.32C11.87 20.6 11.68 20.96 11.38 20.85C8.97 20.03 4.1 17.04 2.52 12.04C2.19 11.02 2 9.92001 2 8.73001C2 5.64001 4.49 3.14001 7.56 3.14001C9.37 3.14001 10.99 4.02001 12 5.37001C13.01 4.02001 14.63 3.14001 16.44 3.14001C19.51 3.14001 22 5.64001 22 8.73001Z" fill="#F95341" />
                </svg>
              }
              <p className="vehiclesWatch">{watchList.length}</p>
              <p className="nameWatchlist">{t('in_watchlist')}</p>
            </div>
            <div className="menuWatchBody">
              {watchList.map(watch => (
                <Link to={`/auction/${watch.vehicle.origin_id}`} key={watch.vehicle.origin_id} className='watch'>
                    <div className="watchHeader">
                      <img src={watch.vehicle.main_photo} className="watchPhoto" alt="main"></img>
                      <div className="carNameWatch">
                        <h2>{watch.vehicle.year} <span>{typeof watch.vehicle.model.make.name === 'string' ? watch.vehicle.model.make.name : 'Unknown Make'}</span> {typeof watch.vehicle.model.name === 'string' ? watch.vehicle.model.name : 'Unknown Model'}</h2>
                        <h3>{formatNumber(watch.vehicle.odometr)} {t('km')}<span> | {watch.vehicle.trim} | {watch.vehicle.drive_train.name}</span></h3>
                      </div>
                    </div>
                    <hr />
                      <div className="bidsPriceWatch">
                        {watch.vehicle.current_bid ?
                          <>
                            <p>{watch.vehicle.increment_bid} {t('bids')}</p>
                            <h2>${formatNumber(watch.vehicle.current_bid)}</h2>
                          </>
                          :
                          <h2 style={{ fontSize: '14px' }}>{t('no_bids')}</h2>
                        }
                      </div>
                </Link>
              ))}
            </div>
          </div>
        </>
      }
    </div>
  );
}

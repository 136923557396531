import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css';
import Skelet from '../components/Skelet/Skelet';
import Welcome from '../pages/Welcome/Welcome';
import Login from '../pages/Login/Login';
import Registration from '../pages/Registration/Registration';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import EnterPassword from '../pages/EnterPassword/EnterPassword';
import Auction from '../pages/Auction/Auction';
import Item from '../pages/Item/Item';
import Profile from '../pages/Profile/Profile';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addVehicle, clearWatchlist } from '../store'



function App() {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const fetchLogin = async () => {
      const response = await fetch('/api/pre-login');
      if (response.ok) {
        setLogin(true);
      }
      setLoading(false);
    }

    fetchLogin();
  }, [])

  const dispatch = useDispatch();

  useEffect(() => {
  const fetchWatchList = async () => {
    if (login) {
      const response = await fetch('/api/watchlist?email=' + localStorage.getItem('email'));
      if (response.ok) {
        const data = await response.json();
        dispatch(clearWatchlist());
        data.watchlist.map(car => (
          dispatch(addVehicle(car))
        ))
      }
    }
  }
    fetchWatchList();
  }, [login, dispatch])
  
  useEffect(() => {
    const handleResize = () => 
      setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
})

  if (loading) {
    return (
      <div className='loading'>
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 24 24">
          <path fill="none" stroke="black" strokeDasharray="16" strokeDashoffset="16" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3c4.97 0 9 4.03 9 9">
            <animate fill="freeze" attributeName="stroke-dashoffset" dur="0.2s" values="16;0" />
            <animateTransform attributeName="transform" dur="1.5s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
          </path>
        </svg>
      </div>
    )
  }


  return (
    <Routes>
      <Route path='/' element={<Skelet width={width} t={t} i18n={i18n} textBtn={'Sign up'} userLoggedIn={login} setLogin={setLogin} />} >
        <Route index element={<Welcome t={t} textBtn={login ? ('browse') : ('Sign up')} login={login}/>} />
        {!login ? (
          <>
            <Route path='/login' element={<Login t={t} textBtn={'login'} setLogin={setLogin} />} />
            <Route path='/registration' element={<Registration t={t} textBtn={'create'} />} />
            <Route path='/login/:recovery' element={<ForgotPassword t={t} textBtn={'passwordRecovery'} />} />
            <Route path='/reset/:userId/:TOKEN' element={<EnterPassword t={t} textBtn={'passwordRecovery'} />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/auction" replace />} />
        )}
        {login ? (
          <>
            <Route path='/auction' element={<Auction width={width} t={t} />} />
            <Route path='/auction/:id' element={<Item width={width} t={t} />} />
            <Route path='/profile' element={<Profile width={width} t={t} />} />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" replace />} />
        )}
      </Route>
    </Routes>
  );
}

export default App;

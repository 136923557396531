import React from 'react'
import { Link } from 'react-router-dom';
import './signUpbtn.css'

export default function SignUpBtn({ t, textBtn, onClick, isClicked, style, styleHeader }) {

  return (
    <Link to='/registration' style={style}>
      <button 
      style={styleHeader ? styleHeader : style}
      className={`signUpBtn ${isClicked ? 'clicked' : ''}`}
      onClick={onClick}
      >
        {t(textBtn)}
      </button>
    </Link>
  )
}

import React, { useState } from 'react';
import './saveFilters.css';
import { Icon } from '@iconify/react';

export default function SaveFilters({ userFilters, filter, setFilter, fetchFilters, t }) {
    const [isClicked, setIsClicked] = useState(false);
    const [filterName, setFilterName] = useState('');

    const handleInputChange = (e) => {
        setFilterName(e.target.value);
    };

    const handleSaveFilter = async () => {
        if (filter.make !== null ||
            filter.models !== null ||
            filter.year_st !== null ||
            filter.year_en !== null ||
            filter.odometer_st !== null ||
            filter.odometer_en !== null) {
            setTimeout(() => {
                setIsClicked(true);
                setTimeout(() => {
                    setIsClicked(false);
                }, 600); // Убираем тень через 600 мс
            }, 0);

            const filterData = {
                ...filter,
                name: filterName, // Добавление filterName в объект
            };

            const response = await fetch('/api/filters', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filterData)
            })
            if (response.ok) {
                setFilterName('');
                fetchFilters();
            }
        }
    };

    const handleDeleteFilter = async (id) => {
        const response = await fetch('/api/filters?filter_id=' + id, {
            method: 'DELETE',
        });
        if (response.ok) {
            fetchFilters();
        }
    };

    const handleApplyFilter = (filter) => {
        setFilter(prevFilter => ({
            ...prevFilter,
            make: filter.make,
            models: filter.models.length === 0 ? null : filter.models,
            year_st: filter.year_st,
            year_en: filter.year_en,
            odometer_st: filter.odometr_st,
            odometer_en: filter.odometr_en,
        }));
    };

    return (
        <div className='saveFilters'>
            <p>{t('custom')}</p>
            <input 
                type="text"
                id='save'
                value={filterName}
                onChange={handleInputChange}
                placeholder={t('enter')}
            />
            <button className={`saveItem ${isClicked ? 'clicked' : ''}`} onClick={handleSaveFilter}>
                {t('save')}
            </button>
            <div className="savedItems">
                {userFilters.map(filter => (
                    <div key={filter.id} className='item' onClick={() => handleApplyFilter(filter)}>
                        <p>{filter.name}</p>
                        <Icon
                            icon="material-symbols-light:close"
                            width="17px" height="17px"
                            style={{ color: 'black' }}
                            onClick={() => handleDeleteFilter(filter.id)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

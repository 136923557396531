import React, { useEffect, useRef, useState } from 'react';
import '../Filters/filters.css';

export default function CustomSelect({
    nameFilter,
    setIsOpen,
    isOpen,
    setSearchValue,
    searchValue,
    options,
    filter,
    setFilter,
    t,
    width
}) {
    const containerRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleSearch = (e) => setSearchValue(e.target.value);

    const handleSelectAll = () => {
        if (nameFilter === 'Makes') {
            setFilter(prevFilter => ({
                ...prevFilter,
                make: null,
                models: null
            }));
        } else {
            setFilter(prevFilter => ({
                ...prevFilter,
                models: null
            }));
        }
    };

    const handleOptionChange = (value) => {
        if (nameFilter === 'Makes') {
            setFilter(prevFilter => ({
                ...prevFilter,
                make: value,
                models: null
            }));
        } else {
            setFilter(prevFilter => {
                if (prevFilter.models !== null && prevFilter.models.includes(value)) {
                    const newModels = prevFilter.models.filter(model => model !== value);
                    return {
                        ...prevFilter,
                        models: newModels.length > 0 ? newModels : null
                    };
                } else {
                    return {
                        ...prevFilter,
                        models: prevFilter.models === null
                            ? [value]
                            : [...prevFilter.models, value]
                    };
                }
            });
        }
    };

    const handleClear = () => {
        setTimeout(() => {
            setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false);
            }, 600);
        }, 0);

        if (nameFilter === 'Makes') {
            if (filter.make !== null) {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    make: null,
                    models: null
                }));
            }
        } else {
            if (filter.models !== null) {
                setFilter(prevFilter => ({
                    ...prevFilter,
                    models: null
                }));
            }
        }

    };

    return (
        <div className="custom-select" ref={width <= 1200 ? containerRef : null} onClick={width <= 1200 ? toggleDropdown : null}>
            <span>{width <= 1200 ? (
                nameFilter === 'Makes'
                    ? (filter.make === null
                        ? nameFilter
                        : (options.find(option => option.id === filter.make)?.name || 'Unknown'))
                    : filter.models === null
                        ? nameFilter
                        : filter.models.length + ' ' + t('selected')
            )
                :
                nameFilter
            }</span>
            <div ref={containerRef}>
                {/* <div className={`select-header ${selectedOptions.length < options.length ? 'more' : ''}`} onClick={toggleDropdown}> */}
                {width <= 1200 ?
                    ''
                    :
                    <div className="select-header" onClick={toggleDropdown}>
                        <p>
                            {nameFilter === 'Makes'
                                ? (filter.make === null
                                    ? t('all')
                                    : (options.find(option => option.id === filter.make)?.name || 'Unknown'))
                                : filter.models === null
                                    ? t('all')
                                    : filter.models.length + ' ' + t('selected')}
                        </p>
                        <button onClick={toggleDropdown} className="dropdown-button">
                            <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.77156 7.7656L5.02036 4.5176C5.17156 4.3656 5.25636 4.16 5.25476 3.9456C5.25236 3.7312 5.16516 3.5264 5.01156 3.3776L1.76276 0.225604C1.44596 -0.0815964 0.938762 -0.0735964 0.631562 0.243204C0.324362 0.560004 0.331562 1.0672 0.649162 1.3744L3.31476 3.96L0.640362 6.6344C0.327562 6.9464 0.327562 7.4536 0.640362 7.7656C0.952362 8.0784 1.45956 8.0784 1.77156 7.7656Z" fill="#707070" />
                            </svg>
                        </button>
                    </div>
                }
                {isOpen && (
                    <>
                        {width >= 1200 &&
                            <svg className='menuVector' xmlns="http://www.w3.org/2000/svg" width='20' height='10' viewBox="0 0 24 24"><path fill="white" d="m7 10l5 5l5-5z" /></svg>
                        }
                        <div className="dropdown-menu">
                            <div>
                                <input
                                    type="text"
                                    placeholder={`Search ${nameFilter}`}
                                    id='search'
                                    value={searchValue}
                                    onChange={handleSearch}
                                    className="search-input"
                                />
                                <div className="select-all">
                                    <label>
                                        <input
                                            type="checkbox"
                                            id='all'
                                            onChange={handleSelectAll}
                                            checked={nameFilter === 'Makes' ? filter.make === null : filter.models === null}
                                        />
                                        {t('all')} {nameFilter.toLowerCase()} {t('selected_small')}
                                    </label>
                                </div>
                                <hr className='hr' />
                            </div>
                            <div className="options-list">
                                {options.map(option => (
                                    <div key={option.id} className="option" onClick={() => handleOptionChange(option.id)}>
                                        <label htmlFor={`option-${option.id}`}>
                                            <input
                                                type="checkbox"
                                                id={`option-${option.id}`}
                                                value={option.name}
                                                checked={nameFilter === 'Makes'
                                                    ? filter.make === option.id
                                                    : filter.models !== null && filter.models.includes(option.id)}
                                                onChange={() => handleOptionChange(option.id)}
                                            />
                                            {option.name}
                                        </label>
                                    </div>
                                ))}
                                {/* {filteredOptions.map(option => (
                                    <div key={option.value} className="option">
                                        <label htmlFor={`option-${option.value}`}>
                                            <input
                                                type="checkbox"
                                                id={`option-${option.value}`} // уникальный id
                                                value={option.value}
                                                checked={selectedOptions.includes(option.value)}
                                                onChange={() => handleOptionChange(option.value)}
                                            />
                                            {option.label}
                                        </label>
                                    </div>
                                ))} */}

                            </div>
                            <div>
                                <hr className='hr' />
                                <div className="dropdown-footer">
                                    {/* <button className={`clearbtn ${isClicked ? 'clicked' : ''}`} onClick={handleClear}>Clear</button> */}
                                    <button className={`clearbtn ${isClicked ? 'clicked' : ''}`} onClick={handleClear} >{t('clear')}</button>
                                    <button className="applybtn" onClick={toggleDropdown}>{t('close')}</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

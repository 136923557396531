import React from 'react'
import './footer.css'

export default function Footer({ t }) {
  return (
    <div className='footer'>
        <p>{t('rights')}</p>
    </div>
  )
}
